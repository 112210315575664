import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import {
    AmountType, Bank, BankAmountType, BankWithAmountTypes,
    Broker, InvoiceBanksBonds, RateChangeSummary, UpdateBondRateParam
} from '../../../models';
import { AmountTypeEnum } from '../../../../src/models/enums';
import { BlcDatePipe } from '../../../../src/pipes';

import { AuthorizationService, BankService, BondService, BrokerService } from '../../../services';

@Component({
    selector: 'app-edit-bank',
    templateUrl: './edit-bank.component.html',
    styleUrls: ['./edit-bank.component.css']
})
export class EditBankComponent implements OnInit {
    @Input() amountTypes: AmountType[] = [];
    @Input() bankRecord: BankWithAmountTypes = null;
    @Input() brokerList: Broker[] = [];
    @Input() displayEditDialog: boolean = false;
    @Input() editBankViewOnly: boolean = false;
    @Input() modifierId: number = 0;
    @Input() searchKeyword: string = null;
    @Input() selectedBankAmountTypes: string[] = [];

    @Output() onBankSearchEnter: EventEmitter<any> = new EventEmitter();
    @Output() onEditDialogClose: EventEmitter<any> = new EventEmitter();
    @Output() sendMessageEmit = new EventEmitter();

    bankAmountTypes: BankAmountType[] = [];
    bankInvoices: InvoiceBanksBonds[];
    bankOrSurety: string = '0';
    bankOrSuretyTypes: any[] = [
        { label: `-- Select Type --`, field: `0` },
        { label: `Bank`, field: `1` },
        { label: `Surety`, field: `2` }
    ];
    bondRateParam: UpdateBondRateParam = new UpdateBondRateParam();
    fgAvailableCommitment: number;
    fgCommitAmount: number;
    invoiceListColumnHeaders: any[] = [
        { field: 'dateAp', header: 'AP Date', width: '20%' },
        { field: 'bondNumber', header: 'Bond/LC Number', width: '20%' },
        { field: 'amount', header: 'Invoice Amount', width: '20%' },
        { field: 'datePayPeriodStart', header: 'Pay Period Start', width: '20%' },
        { field: 'datePayPeriodEnd', header: 'Pay Period End', width: '20%' }
    ];
    isFinancials: boolean = false;
    isSaveReady: boolean = true;
    maxAmount: number = 999999999999.99; // 999 billion instead of 922337203685477.58 = Max value for SQL DB Type Money
    modifier: number = 0;
    modifiedBy: string = 'BLC System';
    rateChangeSummaryHeaderCols: any[] = [
        { field: 'name', header: 'Bank/Surety', width: '5%' },
        { field: 'type', header: 'Type', width: '2%' },
        { field: 'oldStandardRate', header: 'Former Rate', width: '2%' },
        { field: 'newStandardRate', header: 'New Rate', width: '2%' },
        { field: 'dateModified', header: 'Date Modified', width: '2%' },
        { field: 'effectiveDate', header: 'Effective Date', width: '2%' },
        { field: 'currentAmount', header: '# of Instruments Affected', width: '2%' }
    ];
    rateChangeSummaryList: any[] = [];
    sub: any = null;
    totalCurrentAmount: number = 0;
    validRateChange: boolean = false;

    constructor(private authorizationService: AuthorizationService,
        private bankService: BankService,
        private blcDatePipe: BlcDatePipe,
        private bondService: BondService,
        private brokerService: BrokerService,
        private currencyPipe: CurrencyPipe,
        private messageService: MessageService) { }

    ngOnInit(): void {
        //  set the modifier !
        this.modifiedBy = this.authorizationService.getPulteUsername();

        if (this.brokerList.length === 0) {
            this.brokerService.getBrokers().subscribe((brokers) => {
                this.brokerList = brokers;
            });
        }

        this.bankRecord.amountTypes = this.selectedBankAmountTypes.map(Number);
        this.bankRecord.commitAmount = this.bankRecord.commitAmount ?? 0.00;
        this.bankRecord.dateIndemnityAgreement = this.bankRecord.dateIndemnityAgreement ? this.blcDatePipe.transform(this.bankRecord.dateIndemnityAgreement) : '';
        this.bankRecord.modifiedBy = this.modifiedBy;
        this.bankRecord.rate = this.bankRecord.rate ?? 0.00;

        this.bondRateParam.oldStandardRate = this.bankRecord.rate;
        this.bondRateParam.oldFinancialGuaranteeRate = this.bankRecord.financialGuaranteeRate;
        this.bondRateParam.effectiveDate = (this.bondRateParam.effectiveDate !== null ||
            this.bondRateParam.effectiveDate !== undefined || this.bondRateParam.effectiveDate !== '') ?
            this.blcDatePipe.transform(new Date()) : null;
        this.bondRateParam.dateModified = (this.bondRateParam.dateModified !== null || this.bondRateParam.dateModified !== undefined ||
            this.bondRateParam.dateModified !== '') ? this.bankRecord.dateModified : null;

        this.bankOrSurety = this.bankRecord.isSurety ? '2' : '1';

        if (this.selectedBankAmountTypes.includes(AmountTypeEnum.FinancialGuarantee.toString())) {
            this.isFinancials = true;
        }

        this.fgCommitAmount = this.bankRecord.financialGuaranteeCommitAmount;
        this.fgAvailableCommitment = this.bankRecord['financialGuaranteeAvailableCommitment'];
        this.totalCurrentAmount = this.fgCommitAmount - this.fgAvailableCommitment;
    }

    closeEditBankModal(): void {
        this.bankRecord = new BankWithAmountTypes();
        this.resetLocalObjects();
        this.displayEditDialog = false;
        this.onBankSearchEnter.emit({ data: this.searchKeyword });
        this.onEditDialogClose.emit(false);
    }

    getBankInvoices() {
        this.bankService.getBankInvoices(this.bankRecord.id).subscribe((invoices: InvoiceBanksBonds[]) => {
            this.bankInvoices = invoices;
        });
    }

    initializeCopyTooltip() {
        return this.bankRecord.isSurety ?
            'Copy Surety Name to Clipboard' :
            'Copy Bank Name to Clipboard';
    }

    initializeDialogHeader() {
        return this.bankRecord.isSurety ?
            `Edit Surety: ${this.bankRecord.name} : #${this.bankRecord.id}` :
            `Edit Bank: ${this.bankRecord.name} : #${this.bankRecord.id}`;
    }

    initializeInvoiceHeader() {
        return this.bankRecord.isSurety ?
            `Surety: ${this.bankRecord.name} with ${this.bankInvoices?.length === undefined ? '0' : this.bankInvoices?.length} matches` :
            `Bank: ${this.bankRecord.name} with ${this.bankInvoices?.length === undefined ? '0' : this.bankInvoices?.length} matches`;
    }

    isExceptionAccess(): boolean {
        return this.authorizationService.isBlcExceptionsUser();
    }

    isFullAccess(): boolean {
        return this.authorizationService.isBlcFullAccessUser();
    }

    isReadOnlyAccess(): boolean {
        return this.authorizationService.isBlcReadOnlyUser();
    }

    isNewFinancialGuaranteeRateChangeValid(): any {
        if (this.bondRateParam.newFinancialGuaranteeRate === null || this.bondRateParam.newFinancialGuaranteeRate === undefined) {
            return { isValid: false, message: 'You must enter a new financial guarantee rate to retroactively change rates on existing bond/LC instruments.' };
        }

        if (this.bondRateParam.newFinancialGuaranteeRate < 0) {
            return { isValid: false, message: 'Please enter a valid new financial guarantee rate.' };
        }

        return { isValid: true, message: '' };
    }

    isNewStandardRateChangeValid(): any {
        if (this.bondRateParam.newStandardRate === null || this.bondRateParam.newStandardRate === undefined) {
            return { isValid: false, message: 'You must enter a new Standard rate to retroactively change rates on existing bond/LC instruments.' };
        }

        if (this.bondRateParam.newStandardRate < 0) {
            return { isValid: false, message: 'Please enter a valid new standard rate.' };
        }

        return { isValid: true, message: '' };
    }

    isRateChangeValid(): boolean {
        let retval = this.isNewStandardRateChangeValid();
        if (retval.isValid === false) {

            this.messageService.add({
                severity: 'error',
                summary: 'Rate Change Summary Error',
                detail: retval.message
            });

            return false;
        }

        retval = this.isNewFinancialGuaranteeRateChangeValid();
        if (retval.isValid === false) {

            this.messageService.add({
                severity: 'error',
                summary: 'Rate Change Summary Error',
                detail: retval.message
            });

            return false;
        }
        return true;
    }

    onActiveChange(event: any, ctrl: any): void {
        if (ctrl.checked) {
            if (this.bankRecord.dateIndemnityAgreement === '' || this.bankRecord.dateIndemnityAgreement === undefined ||
                this.bankRecord.dateIndemnityAgreement === null) {
                this.bankRecord.isActive = false;
                event.checked = false;
                ctrl.checked = false;
                this.messageService.add({
                    severity: 'error',
                    summary: 'Bank Active Error',
                    detail: `An Agreement Date is required for a bank or surety to be flagged as Active.`
                });
                return;
            } else {
                this.bankRecord.isActive = ctrl.checked;
            }
        }
        this.onFieldChange();
    }

    onBankDescriptionChange(event: any): void {
        this.bankRecord.description = event.target.value;
        this.onFieldChange();
    }

    onBrokerChange(event: any): void {
        this.bankRecord.brokerId = event.target.value;
        this.onFieldChange();
    }

    onCopyNameClick(): void {
        try {
            navigator.clipboard.writeText(this.bankRecord.name);
            if (this.bankRecord.isSurety) {
                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Surety Name copied to clipboard.' });
            } else {
                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Bank Name copied to clipboard.' });
            }
        } catch (ex) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Unable to copy Bank Name to clipboard.' });
        }
    }

    onCounterSignatureRequirementsChange(event: any): void {
        this.bankRecord.counterSignatureRequirements = event.target.value;
        this.onFieldChange();
    }

    onDateIndemnityAgreementChange(): void {
        if (this.bankRecord.dateIndemnityAgreement == '' || this.bankRecord.dateIndemnityAgreement == null || this.bankRecord.dateIndemnityAgreement == undefined) {
            this.bankRecord.isActive = false;
            return;
        } else {
            this.bankRecord.isActive = true;
        }
        this.onFieldChange();
    }

    onDateIndemnityAgreementSelect(): void {
        this.bankRecord.isActive = true;
        this.onFieldChange();
    }

    onEffectiveDateChange(): void {
        this.validRateChange = this.rateChangeValidator();
    }

    onFieldChange() {
        if (this.bankRecord.name && this.bankRecord.dateIndemnityAgreement && this.bankRecord.amountTypes && this.bankRecord.commitAmount >= 0.00 && this.bankRecord.rate &&
            (this.bankRecord.isSurety == false || (this.bankRecord.isSurety && this.bankRecord.brokerId))) {
            this.isSaveReady = true;
        } else {
            this.isSaveReady = false;
        }
    }

    onInputCommitAmount(event: any): void {
        if (event.value === null || isNaN(event.value) || event.value < 0) {
            this.bankRecord.commitAmount = 0.00;
            this.onFieldChange();
            return;
        }

        if (event.value > this.maxAmount) {
            this.messageService.add({
                severity: 'error', summary: 'Error',
                detail: 'Commit Amount is out of range. Please set amount to less than ' +
                    this.currencyPipe.transform(this.maxAmount, 'USD', 'symbol', '1.2-2') + '.'
            });

            this.bankRecord.commitAmount = 0.00;
            this.onFieldChange();
            return;
        }

        this.bankRecord.commitAmount = event.value;
        this.onFieldChange();
    }

    onInputFinancialGuaranteeCommitAmount(event: any): void {
        if (isNaN(event.value) || event.value < 0) {
            this.bankRecord.financialGuaranteeCommitAmount = 0;
            return;
        }

        this.bankRecord.financialGuaranteeCommitAmount = event.value;
        this.onFieldChange();
    }

    onInputFinancialGuaranteeRate(event: any): void {
        if (isNaN(event.value) || event.value < 0) {
            this.bankRecord.financialGuaranteeRate = 0;
            return;
        }

        this.bankRecord.financialGuaranteeRate = event.value;
        this.onFieldChange();
    }

    onInputMinimumFeeCharged(event: any): void {
        if (isNaN(event.value) || event.value < 0) {
            this.bankRecord.minimumFee = 0;
            return;
        }

        if (event.value > this.maxAmount) {
            this.messageService.add({
                severity: 'error', summary: 'Error',
                detail: 'Minimum Fee Charged is out of range. Please set amount to less than ' +
                    this.currencyPipe.transform(this.maxAmount, 'USD', 'symbol', '1.2-2') + '.'
            });
            this.bankRecord.minimumFee = 0;
            return;
        }
        this.onFieldChange();
    }

    onInputRate(event: any): void {
        if (isNaN(event.value) || event.value < 0) {
            this.bankRecord.rate = 0;
            return;
        }

        this.bankRecord.rate = event.value;
        this.onFieldChange();
    }

    onNameChange(event: any): void {
        this.bankRecord.name = event.target.value;
        this.onFieldChange();
    }

    onNewFinancialGuaranteeRateChange(event: any): void {
        this.bondRateParam.newFinancialGuaranteeRate = event?.value;
        this.validRateChange = this.rateChangeValidator();
        const result = this.isNewFinancialGuaranteeRateChangeValid();
        if (result.isValid === false) {
            this.messageService.add({
                severity: 'error',
                summary: 'Rate Change Summary Error',
                detail: result.message
            });
        }
    }

    onNewStandardRateChange(event: any): void {
        this.bondRateParam.newStandardRate = event?.value;
        this.validRateChange = this.rateChangeValidator();
        const result = this.isNewStandardRateChangeValid();
        if (result.isValid === false) {
            this.messageService.add({
                severity: 'error',
                summary: 'Rate Change Summary Error',
                detail: result.message
            });
        }
    }

    onPowerOfAttorneyChange(event: any): void {
        this.bankRecord.isPowerOfAttorney = event.checked;
        this.onFieldChange();
    }

    onRateChangeSubmit(): void {
        //  validate some of the data entry !
        if (this.isRateChangeValid() === false) {
            return;
        } else {
            let warnMsg = 'WARNING! You are about to change the rate on ALL active instruments for ' + this.bankRecord.name + '. Do you wish to continue?';
            if (!confirm(warnMsg)) {
                return;
            }
        }

        const bankParam: Bank = JSON.parse(JSON.stringify(this.bankRecord));
        //  assign the newer rates if any !
        bankParam.rate = this.bondRateParam.newStandardRate;
        bankParam.financialGuaranteeRate = this.bondRateParam.newFinancialGuaranteeRate;
        bankParam.dateModified = this.blcDatePipe.transform(new Date());
        bankParam.modifiedBy = this.authorizationService.getPulteUsername();
        //  now that the actual rate of the bank now changed, then do th rest of the downstream change like
        //  bond rates the bank is associated with !
        //  place rate change submit logic here and don't forget to popup message and update the grid !
        this.bondRateParam.bankId = bankParam.id;
        this.bondRateParam.oldStandardRate = (this.bondRateParam.oldStandardRate == null) ? 0 : this.bondRateParam.oldStandardRate;
        this.bondRateParam.oldFinancialGuaranteeRate = (this.bondRateParam.oldFinancialGuaranteeRate == null) ?
            0 : this.bondRateParam.oldFinancialGuaranteeRate;
        this.bondRateParam.dateModified = bankParam.dateModified;
        this.bondRateParam.modifiedBy = this.modifiedBy;
        try {
            forkJoin({
                request1: this.bankService.updateBank(bankParam),
                request2: this.bondService.updateBondRate(this.bondRateParam)
            }).pipe(
                switchMap(() => {
                    this.messageService.add({
                        severity: 'success',
                        summary: (bankParam.isSurety) ? 'Surety Record Change Success' : `Bank Record Change Success`,
                        detail: (bankParam.isSurety) ? `Record changed for Surety ${bankParam.name}` : `Record changed for Bank ${bankParam.name}`
                    });

                    return this.bankService.getRateChangeSummary(this.bondRateParam.dateModified, this.bondRateParam.modifiedBy);
                }),
            ).subscribe((rateChangeSummary: RateChangeSummary[]) => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Rate Change Summary Success',
                    detail: `Rate Change Summary for ${this.bankRecord.name}`
                });

                this.rateChangeSummaryList = [];
                for (const rcs of rateChangeSummary) {
                    this.bankRecord.rate = rcs.newStandardRate;
                    this.bankRecord.financialGuaranteeRate = rcs.newFinancialGuaranteeRate;
                    rcs.effectiveDate = this.bondRateParam.effectiveDate;
                    this.rateChangeSummaryList.push({
                        name: rcs.name,
                        type: `Standard`,
                        oldStandardRate: rcs.oldStandardRate,
                        newStandardRate: (rcs.newStandardRate == null) ? `` : rcs.newStandardRate,
                        dateModified: this.blcDatePipe.transform(rcs.dateModified),
                        effectiveDate: (rcs.newStandardRate == null) ? `N/A` : (rcs.effectiveDate == null) ?
                            `ALL` : this.blcDatePipe.transform(rcs.effectiveDate),
                        currentAmount: (rcs.standardCount == 0) ? (rcs.newStandardRate == null) ?
                            `N/A` : `No instruments exist for this type` : rcs.standardCount
                    });
                    this.rateChangeSummaryList.push({
                        name: rcs.name,
                        type: `Financial Guarantee`,
                        oldStandardRate: rcs.oldFinancialGuaranteeRate,
                        newStandardRate: (rcs.newFinancialGuaranteeRate == null) ? `` : rcs.newFinancialGuaranteeRate,
                        dateModified: this.blcDatePipe.transform(rcs.dateModified),
                        effectiveDate: (rcs.newFinancialGuaranteeRate == null) ? `N/A` : (rcs.effectiveDate == null) ?
                            `ALL` : this.blcDatePipe.transform(rcs.effectiveDate),
                        currentAmount: (rcs.financialGuaranteeCount == 0) ? (rcs.newFinancialGuaranteeRate == null) ?
                            `N/A` : `No instruments exist for this type` : rcs.financialGuaranteeCount
                    });
                }

                this.bondRateParam = new UpdateBondRateParam();
                //  init the old rate and financial guarantee old rate to the current bank record's
                this.bondRateParam.oldStandardRate = this.bankRecord.rate;
                this.bondRateParam.oldFinancialGuaranteeRate = this.bankRecord.financialGuaranteeRate;
                this.bondRateParam.effectiveDate = (this.bondRateParam.effectiveDate !== null ||
                    this.bondRateParam.effectiveDate !== undefined || this.bondRateParam.effectiveDate !== '') ?
                    this.blcDatePipe.transform(new Date()) : null;
                this.bondRateParam.dateModified = (this.bondRateParam.dateModified !== null ||
                    this.bondRateParam.dateModified !== undefined || this.bondRateParam.dateModified !== '') ?
                    this.bankRecord.dateModified : null;
                this.bondRateParam.modifiedBy = this.modifiedBy;
            });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Rate Change Submission process(es) Error(s)',
                detail: 'Rate Change Submission process(es) Error(s)'
            });
        } finally {
            this.validRateChange = false;
        }
    }

    onRevolverChange(event: any): void {
        this.bankRecord.isRevolver = event.checked;
        this.onFieldChange();
    }

    onSelectedAmountTypeChange(): void {
        this.isFinancials = false;
        this.bankRecord.amountTypes = [];
        for (const amountType of this.selectedBankAmountTypes) {
            if (amountType === AmountTypeEnum.FinancialGuarantee.toString()) {
                this.isFinancials = true;
            }
            this.bankRecord.amountTypes.push(Number(amountType));
        }
        this.onFieldChange();
    }

    onSubmit(): void {
        this.bankService.getBanks(true)
            .pipe(
                switchMap((results: Bank[]) => {
                    results.sort((a, b) => a.name.localeCompare(b.name));
                    const badName = results.find(x => x.name === this.bankRecord.name);
                    if (badName && this.bankRecord.id !== badName?.id) {
                        throw `The Bank/Surety name: ${badName?.name} already exist. Please pick another.`;
                    }

                    const validator = this.validatedBank(this.bankRecord);
                    if (validator.valid === false) {
                        throw validator.message;
                    }
                    //  before sending to API, make sure that Broker ID is 0 if IsSurety is false !
                    //  on the flip side, if Is Surety however, make sure the IsRevolver is False !
                    if (this.bankRecord.isSurety == false) {
                        this.bankRecord.brokerId = 0;
                    } else {
                        this.bankRecord.isRevolver = false;
                    }
                    //  make sure for whatever reason the Financial Guarantee type is not selected at this point then
                    //  financial guarantee commit amount and rate will be null !
                    if (this.isFinancials == false) {
                        this.bankRecord.financialGuaranteeCommitAmount = null;
                        this.bankRecord.financialGuaranteeRate = null;
                    }

                    // Convert agreementDate to a string to avoid timezone related issues
                    this.bankRecord.dateIndemnityAgreement = this.blcDatePipe.transform(this.bankRecord.dateIndemnityAgreement)

                    return this.bankService.updateBankWithAmountTypes(this.bankRecord);
                })
            ).subscribe((result: number) => {
                this.sendMessage(
                    'success',
                    this.bankRecord.isSurety === true ? `Surety Record Updated` : `Bank Record Updated`,
                    this.bankRecord.isSurety === true ? `Your Surety has been updated.` : `Your Bank has been updated.`
                );
            }, (err: any) => {
                this.sendMessage('error', 'Invalid Bank Record', err);
                this.sendMessage('error', 'Bank/Surety Record Creation Error', `Your Bank/Surety was not created.`);
            }, () => {
                //  close out !
                this.onBankSearchEnter.emit({ data: this.searchKeyword });
                this.onEditDialogClose.emit(this.displayEditDialog);
            });
    }

    onTermsChange(event: any): void {
        this.bankRecord.terms = event.target.value;
    }

    onTypeChange(event: any): void {
        this.bankOrSurety = event.target.value;
        if (this.bankOrSurety === '1') {
            this.bankRecord.isSurety = false;
        } else if (this.bankOrSurety === '2') {
            this.bankRecord.isSurety = true;
        } else {
            this.bankRecord.isSurety = null;
        }
        this.onFieldChange();
    }

    rateChangeValidator(): boolean {

        let retval = this.isNewStandardRateChangeValid();
        if (retval.isValid === false) {
            return false;
        }

        retval = this.isNewFinancialGuaranteeRateChangeValid();
        if (retval.isValid === false) {
            return false;
        }

        return true;
    }

    resetLocalObjects(): void {
        this.bankOrSurety = '0';
        this.isFinancials = false;
        this.isSaveReady = false;
    };

    sendMessage(severity: string, summary: string, detail: string): void {
        this.sendMessageEmit.emit([severity, summary, detail]);
    }

    validatedBank(bankRecord: Bank): any {
        if (bankRecord.name === null || bankRecord.name === '' || bankRecord.name === undefined) {
            return { valid: false, message: `Please enter a name for this Bank or Surety.` };
        }

        if (bankRecord.commitAmount === null) {
            return { valid: false, message: `Please enter a commited amount.` };
        }

        if (bankRecord.commitAmount < bankRecord.financialGuaranteeCommitAmount) {
            return { valid: false, message: `Financial Guarantee Commit Amount must not be more than the Commit Amount.` };
        }

        if (bankRecord.financialGuaranteeCommitAmount < this.totalCurrentAmount) {
            let warnMsg = 'The financial guarantee commit amount is less than the amount already committed';
            if (!confirm(warnMsg)) {
                return;
            }
            return { valid: true, message: `` };
        }

        if (this.bankRecord.singleBondLimit > this.bankRecord.commitAmount) {
            return { valid: false, message: `Single Bond Limit Amount must not exceed the Commit Amount.` };
        }

        if (bankRecord.rate === null) {
            return { valid: false, message: `Please enter a rate.` };
        }

        if (this.bankRecord.amountTypes?.length < 1) {
            return { valid: false, message: `Please select at least one Bond/LC type.` };
        }

        if (this.isFinancials) {
            if (this.bankRecord.financialGuaranteeCommitAmount == null) {
                return { valid: false, message: `Since the Financial Guarantee type is selected, then please enter a Financial Guarantee Commit Amount.` };
            }
        }

        if (bankRecord.isSurety === null) {
            return { valid: false, message: `Please select a type.` };
        }

        if (this.bankRecord.brokerId === null || this.bankRecord.brokerId < 1) {
            if (bankRecord.isSurety) {
                return { valid: false, message: `Please select a Broker for this Surety.` };
            }
        }

        if (bankRecord.isActive === null) {
            return { valid: false, message: `Please either check or uncheck the active checkbox.` };
        }

        if (bankRecord.isPowerOfAttorney === null) {
            return { valid: false, message: `Please either check or uncheck Power Of Attorney.` };
        }

        if (bankRecord.isRevolver === null) {
            return { valid: false, message: `Please either check or uncheck Bank Revolver.` };
        }

        if (bankRecord.dateIndemnityAgreement === null || bankRecord.dateIndemnityAgreement === '' ||
            bankRecord.dateIndemnityAgreement === undefined) {
            return { valid: false, message: `Please enter an Agreement date.` };
        }

        if (bankRecord.isActive) {
            if (bankRecord.dateIndemnityAgreement === null || bankRecord.dateIndemnityAgreement === '' ||
                bankRecord.dateIndemnityAgreement === undefined) {
                return { valid: false, message: `Cannot activate this bank record if the Agreement date is not entered.` };
            }
        }

        return { valid: true, message: '' };
    }
}
