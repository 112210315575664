// Angular imports
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { OAuthModule } from 'angular-oauth2-oidc';
import { RouterModule, Routes } from '@angular/router';

// PrimeNG imports
import { AutoCompleteModule } from 'primeng/autocomplete'
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessageModule } from 'primeng/message';
import { MessageService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { PanelModule } from 'primeng/panel';
import { PickListModule } from 'primeng/picklist';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// Main component imports
import { AppComponent } from './app.component';
import { CreateAmendmentComponent } from './create-amendment/create-amendment.component';
import { CreateBankComponent } from './create-bank/create-bank.component';
import { CreateBondComponent } from './create-bond/create-bond.component';
import { CreateInvoiceComponent } from './create-invoice/create-invoice.component';
import { DataCacheManagerComponent } from './data-cache-manager/data-cache-manager.component';
import { EditAmendmentComponent } from './edit-amendment/edit-amendment.component';
import { EditBankComponent } from './search-bank/edit-bank/edit-bank.component';
import { EditBondComponent } from './search-bond/edit-bond/edit-bond.component';
import { EditInvoiceComponent } from './edit-invoice/edit-invoice.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { NavigationComponent } from './navigation/navigation.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { SearchBankComponent } from './search-bank/search-bank.component';
import { SearchBondComponent } from './search-bond/search-bond.component';
import { UnassignedComponent } from './unassigned/unassigned.component';
import { UnauthenticatedComponent } from './unauthenticated/unauthenticated.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

// Report component imports
import { BankAndSuretyListingComponent } from './reports/bank-and-surety-listing/bank-and-surety-listing.component';
import { BLCAmendmentComponent } from './reports/b-lc-amendment/b-lc-amendment.component';
import { CombinedDetailComponent } from './reports/combined-detail/combined-detail.component';
import { CombinedSummaryComponent } from './reports/combined-summary/combined-summary.component';
import { CostToCompleteByCompanyComponent } from './reports/cost-to-complete-by-company/cost-to-complete-by-company.component';
import { CostToCompleteBySuretyComponent } from './reports/cost-to-complete-by-surety/cost-to-complete-by-surety.component';
import { InvoiceByBankSuretyComponent } from './reports/invoice-by-bank-surety/invoice-by-bank-surety.component';
import { InvoiceByCompanyComponent } from './reports/invoice-by-company/invoice-by-company.component';
import { LcSuretyBondPricingAnalysisComponent } from './reports/lc-surety-bond-pricing-analysis/lc-surety-bond-pricing-analysis.component';
import { ReportResultsComponent } from './reports/report-results/report-results.component';

// Utility component imports
import { InvoiceUploadComponent } from './utilities/invoice-upload/invoice-upload.component';
import { InvoiceUploadResultsComponent } from './utilities/invoice-upload/invoice-upload-results/invoice-upload-results.component';

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// Class imports
import { LocalStorage } from "../classes/localstorage";
import { SessionStorage } from "../classes/sessionstorage";

// Component (shared) imports
import { LoadingSpinnerComponent, NotificationsComponent } from '../components';

// Factory imports
import { getStorageFactory } from "../factories/storagefactory";

// Functions (shared) imports
import { CommonFunctions } from '../functions';

// Guard imports
import { AuthGuard } from '../guards';

// Interceptor imports
import { LoadingIndicatorInterceptor } from '../interceptors';

// Custom Pipe imports
import { AmountSubTypeNamePipe, AmountTypeNamePipe, BankNamePipe, BlcDatePipe, CommunityNamePipe, CompanyNamePipe, NegativeParenthesesPipe } from '../pipes';

// Service imports
import {
    AmendmentService, AmountSubTypeService, AmountTypeService, AuthorizationService, BankAmountTypeService, BankService, BondService,
    BrokerService, ClientDataCacheService, CommunityService, CompanyService, DocumentService, ExcelService, InvoiceService, LoadingIndicatorService,
    NotificationsService, ReportService, ServerDataCacheService, SharedService
} from '../services';

// Miscellaneous imports
import { SECURE_LOCAL_STORAGE, SECURE_SESSION_STORAGE, STORAGE } from '../classes/app.constants';
import { ClaimsEnum } from '../models/enums/claims.enum';
import { environment } from '../environments/environment';
import { AutoFocus } from './directives/auto-focus.directive';

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

const appRoutes: Routes = [
    {
        path: '', component: LoginComponent, pathMatch: 'full'
    },
    {
        path: 'search-bank', component: SearchBankComponent, canActivate: [AuthGuard],
        pathMatch: 'full', data: { requiresClaim: ClaimsEnum.blcfullaccess }
    },
    {
        path: 'search-bond', component: SearchBondComponent, canActivate: [AuthGuard],
        pathMatch: 'full', data: { requiresClaim: ClaimsEnum.blcfullaccess }
    },
    {
        path: 'edit', component: EditBondComponent, canActivate: [AuthGuard],
        pathMatch: 'full', data: { requiresClaim: ClaimsEnum.blcfullaccess }
    },
    {
        path: 'create-bond', component: CreateBondComponent, canActivate: [AuthGuard],
        pathMatch: 'full', data: { requiresClaim: ClaimsEnum.blcfullaccess }
    },
    {
        path: 'create-bank', component: CreateBankComponent, canActivate: [AuthGuard],
        pathMatch: 'full', data: { requiresClaim: ClaimsEnum.blcfullaccess }
    },
    {
        path: 'bank-and-surety-listing', component: BankAndSuretyListingComponent, canActivate: [AuthGuard],
        pathMatch: 'full', data: { requiresClaim: ClaimsEnum.blcfullaccess }
    },
    {
        path: 'invoice-by-bank-surety', component: InvoiceByBankSuretyComponent, canActivate: [AuthGuard],
        pathMatch: 'full', data: { requiresClaim: ClaimsEnum.blcfullaccess }
    },
    {
        path: 'invoice-by-company', component: InvoiceByCompanyComponent, canActivate: [AuthGuard],
        pathMatch: 'full', data: { requiresClaim: ClaimsEnum.blcfullaccess }
    },
    {
        path: 'lc-surety-bond-pricing-analysis', component: LcSuretyBondPricingAnalysisComponent, canActivate: [AuthGuard],
        pathMatch: 'full', data: { requiresClaim: ClaimsEnum.blcfullaccess }
    },
    {
        path: 'combined-summary', component: CombinedSummaryComponent, canActivate: [AuthGuard],
        pathMatch: 'full', data: { requiresClaim: ClaimsEnum.blcfullaccess }
    },
    {
        path: 'b-lc-amendment', component: BLCAmendmentComponent, canActivate: [AuthGuard],
        pathMatch: 'full', data: { requiresClaim: ClaimsEnum.blcfullaccess }
    },
    {
        path: 'combined-detail', component: CombinedDetailComponent, canActivate: [AuthGuard],
        pathMatch: 'full', data: { requiresClaim: ClaimsEnum.blcfullaccess }
    },
    {
        path: 'cost-to-complete-by-company', component: CostToCompleteByCompanyComponent, canActivate: [AuthGuard],
        pathMatch: 'full', data: { requiresClaim: ClaimsEnum.blcfullaccess }
    },
    {
        path: 'cost-to-complete-by-surety', component: CostToCompleteBySuretyComponent, canActivate: [AuthGuard],
        pathMatch: 'full', data: { requiresClaim: ClaimsEnum.blcfullaccess }
    },
    {
        path: 'invoice-upload', component: InvoiceUploadComponent, canActivate: [AuthGuard],
        pathMatch: 'full', data: { requiresClaim: ClaimsEnum.blcfullaccess }
    },
    {
        path: 'data-cache-manager', component: DataCacheManagerComponent, canActivate: [AuthGuard],
        pathMatch: 'full', data: { requiresClaim: ClaimsEnum.blcfullaccess }
    },
    {
        path: 'unassigned', component: UnassignedComponent, pathMatch: 'full'
    },
    {
        path: 'unauthenticated', component: UnauthenticatedComponent, pathMatch: 'full'
    },
    {
        path: 'unauthorized', component: UnauthorizedComponent, pathMatch: 'full'
    },
    {
        path: '**', redirectTo: 'search-bond'
    }
];

@NgModule({
    providers: [
        AmendmentService,
        AmountSubTypeService,
        AmountTypeService,
        AuthGuard,
        AuthorizationService,
        BankAmountTypeService,
        BankNamePipe,
        BankService,
        BlcDatePipe,
        BondService,
        BrokerService,
        ClientDataCacheService,
        CommonFunctions,
        CommunityNamePipe,
        CommunityService,
        CompanyNamePipe,
        CompanyService,
        CurrencyPipe,
        DatePipe,
        DocumentService,
        ExcelService,
        InvoiceService,
        LoadingIndicatorService,
        MessageService,
        NotificationsService,
        ReportService,
        ServerDataCacheService,
        SharedService,
        Title,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingIndicatorInterceptor, multi: true
        },
        {
            provide: SECURE_LOCAL_STORAGE,
            useFactory: getStorageFactory(LocalStorage)
        },
        {
            provide: SECURE_SESSION_STORAGE,
            useFactory: getStorageFactory(SessionStorage)
        },
        {
            provide: STORAGE,
            useValue: "e992d3bc1af0436eb5906b4293936c7a"
        },
    ],
    declarations: [
        AmountSubTypeNamePipe,
        AmountTypeNamePipe,
        AppComponent,
        AutoFocus,
        BankAndSuretyListingComponent,
        BankNamePipe,
        BLCAmendmentComponent,
        BlcDatePipe,
        CombinedDetailComponent,
        CombinedSummaryComponent,
        CommunityNamePipe,
        CompanyNamePipe,
        CostToCompleteByCompanyComponent,
        CostToCompleteBySuretyComponent,
        CreateAmendmentComponent,
        CreateBankComponent,
        CreateBondComponent,
        CreateInvoiceComponent,
        DataCacheManagerComponent,
        EditAmendmentComponent,
        EditBankComponent,
        EditBondComponent,
        EditInvoiceComponent,
        HomeComponent,
        InvoiceByBankSuretyComponent,
        InvoiceByCompanyComponent,
        InvoiceUploadComponent,
        InvoiceUploadResultsComponent,
        LcSuretyBondPricingAnalysisComponent,
        LoadingSpinnerComponent,
        LoginComponent,
        NavigationComponent,
        NegativeParenthesesPipe,
        NotificationsComponent,
        ReportResultsComponent,
        SearchBankComponent,
        SearchBondComponent,
        ToolbarComponent,
        UnassignedComponent,
        UnauthenticatedComponent,
        UnauthorizedComponent
    ],
    imports: [
        AutoCompleteModule,
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        BrowserAnimationsModule,
        ButtonModule,
        CalendarModule,
        CheckboxModule,
        CommonModule,
        DialogModule,
        FileUploadModule,
        FormsModule,
        HttpClientModule,
        InputNumberModule,
        InputTextareaModule,
        MessageModule,
        MessagesModule,
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: [environment.baseUrl],
                sendAccessToken: true
            }
        }),
        PanelModule,
        PickListModule,
        ProgressSpinnerModule,
        ReactiveFormsModule,
        RouterModule.forRoot(appRoutes, {}),
        TableModule,
        TabViewModule,
        ToastModule
    ],
    exports: [AmountSubTypeNamePipe, AmountTypeNamePipe, BankNamePipe, BlcDatePipe, CommunityNamePipe, CompanyNamePipe, RouterModule, NegativeParenthesesPipe],
    bootstrap: [AppComponent]
})
export class AppModule { }
