import { CurrencyPipe } from '@angular/common';
import { AfterContentInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as fileSaver from 'file-saver';
import { MessageService } from 'primeng/api';
import { AutoComplete } from 'primeng/autocomplete';
import { finalize, switchMap } from 'rxjs/operators';

import { CreateInvoiceComponent } from '../../../../src/app/create-invoice/create-invoice.component';
import { EditAmendmentComponent } from '../../../../src/app/edit-amendment/edit-amendment.component';
import { EditInvoiceComponent } from '../../../../src/app/edit-invoice/edit-invoice.component';

import { CommonFunctions } from '../../../functions';
import {
    Amendment, AmountSubType, AmountType, Bank, BankAmountType, BankWithFinancialGuarantee, Bond,
    Community, Company, Document, DocumentParam, Invoice, InvoiceBanksBonds, User
} from '../../../models';
import { AmountTypeEnum, BLCTypeEnum, BondStatusEnum } from '../../../models/enums';
import { BlcDatePipe } from '../../../pipes';

import {
    AmendmentService, AmountSubTypeService, AuthorizationService, BankAmountTypeService, BankService,
    BondService, CommunityService, CompanyService, DocumentService, InvoiceService
} from '../../../services';

@Component({
    selector: 'app-edit-bond',
    templateUrl: './edit-bond.component.html',
    styleUrls: ['./edit-bond.component.css']
})
export class EditBondComponent implements OnInit, AfterContentInit {
    @ViewChild(CreateInvoiceComponent) createModal: CreateInvoiceComponent;
    @ViewChild('communityNumber') communityAutocomplete: AutoComplete;
    @ViewChild(EditInvoiceComponent) editModal: EditInvoiceComponent;
    @ViewChild(EditAmendmentComponent) editAmendmentModal: EditAmendmentComponent;

    @Input() allAmountSubTypeList: AmountSubType[];
    @Input() amountTypeList: AmountType[];
    @Input() availableCommitment: number;
    @Input() bankOrSuretyList: Bank[];
    @Input() bondData: Bond;
    @Input() bondModifierId: number;
    @Input() companyList: Company[];
    @Input() documents: Document[];
    @Input() editBondViewOnly: boolean = false;
    @Input() fullBanksList: Bank[]
    @Input() isAdvancedSearchClicked: boolean;
    @Input() isAmendmentReport: boolean;
    @Input() isAmountTypeFinancialGuarantee: boolean;
    @Input() isCostToCompleteReport: boolean;
    @Input() isInvoiceReport: boolean;
    @Input() isSearchEditClicked: boolean;
    @Input() isStatusCanceled: boolean;
    @Input() singleBondLimit: any;
    @Input() user: User;

    @Output() childEvent = new EventEmitter();
    @Output() dialogClose = new EventEmitter();
    @Output() onAdvancedSearch = new EventEmitter();
    @Output() onSearchAndEdit = new EventEmitter();
    @Output() sendMessageEmit = new EventEmitter();

    activeIndex: number = 0;
    amendmentData: Amendment;
    amendmentListColumnHeaders: any[];
    amendmentList: Amendment[];
    amountType = AmountTypeEnum;
    amountTypeListFiltered: AmountType[];
    bankAmountTypeList: BankAmountType[];
    bankData: BankWithFinancialGuarantee;
    bankOrSuretyId: number = 0;
    blcType = BLCTypeEnum;
    blcNumber: number = 0;
    bondType: string = '';
    bondInvoices: InvoiceBanksBonds[];
    bondModifiedBy: string = 'BLC System';
    communityList: Community[];
    ctcSaveReady: boolean = true;
    displayAddDialog: boolean = false;
    displayAmendmentDialog: boolean = false;
    displayAmendmentEditDialog: boolean = false;
    displayAmountSubTypeList: AmountType[];
    displayInvoiceDialog: boolean = false;
    documentDtoParam: DocumentParam;
    documentListColumnHeaders: any[];
    documentDescriptionToApply: string;
    editTabDisplayDlg = false;
    fileLimit: number = 3;
    filteredCommunities: Community[] = [];
    haveAccess: any;
    id: number;
    invoiceInformation: Invoice;
    invoiceListColumnHeaders: any[];
    isAddClicked: boolean = false;
    isAmendmentAddClicked: boolean = false;
    isAmountTypeSelected: boolean = true;
    isAutoRenew: boolean;
    isBankInformationChanged: boolean = false;
    isCompanySelected: boolean = true;
    invoiceData: Invoice;
    isCTCDataChanged: boolean = false;
    isDataChanged: boolean;
    isEntryValid: boolean;
    isNumberModified: boolean = false;
    isSaveReady: boolean = false;
    maxAmount: number = 999999999999.99; // 999 billion instead of 922337203685477.58 = Max value for SQL DB Type Money
    maxUploadSize: number = 52428800;  //  file size 50 megabytes - 52428800 bytes
    proxyBlcData: Bond = null;
    rate: number = null;
    rateMaxAmount: number = 10000; // Equals 100% due to (bps)
    selectedCommunity: Community = null;
    showNoResultsMessage: boolean = false;
    success: boolean = false;

    constructor(private authService: AuthorizationService,
        private amendmentService: AmendmentService,
        private amountSubTypeService: AmountSubTypeService,
        private bankAmountTypeService: BankAmountTypeService,
        private bankService: BankService,
        private blcDatePipe: BlcDatePipe,
        private bondService: BondService,
        private commonFunctions: CommonFunctions,
        private communityService: CommunityService,
        private companyService: CompanyService,
        private currencyPipe: CurrencyPipe,
        private documentService: DocumentService,
        private invoiceService: InvoiceService,
        private messageService: MessageService) { }

    ngOnInit(): void {
        //  set record modifier !
        this.bondModifiedBy = this.authService.getPulteUsername();
        this.setActiveIndex();

        if (this.isFullAccessUser()) {
            this.documentListColumnHeaders = [
                { field: 'documentName', header: 'Document Name', width: '40%' },
                { field: 'description', header: 'Description', width: '40%' },
                { field: 'delete', header: 'Delete', width: '10%' },
                { field: 'download', header: 'Download', width: '10%' }
            ];
        } else {
            this.documentListColumnHeaders = [
                { field: 'documentName', header: 'Document Name', width: '40%' },
                { field: 'description', header: 'Description', width: '40%' },
                { field: 'delete', header: '', width: '10%' },
                { field: 'download', header: 'Download', width: '10%' }
            ];
        }

        this.amendmentListColumnHeaders = [
            { field: 'request', header: 'Amendment Request', width: '34%' },
            { field: 'bondNewAmount', header: 'New Amount', width: '18%' },
            { field: 'bondNewDateExpired', header: 'New Expire Date', width: '10%' },
            { field: 'dateMailed', header: 'Mail Date', width: '10%' },
            { field: 'dateEffective', header: 'Effective Date', width: '10%' },
            { field: 'modifiedBy', header: '', width: '4%' },
            { field: 'edit', header: '', width: '8%' },
            { field: 'remove', header: '', width: '6%' }
        ];

        this.invoiceListColumnHeaders = [
            { field: 'dateAp', header: 'AP Date', width: '25%' },
            { field: 'amount', header: 'Invoice Amount', width: '25%' },
            { field: 'datePayPeriodStart', header: 'Pay Period Start', width: '25%' },
            { field: 'datePayPeriodEnd', header: 'Pay Period End', width: '25%' }
        ];

        this.getAmountSubTypes(0, true);

        this.documentDtoParam = new DocumentParam();
        this.editTabDisplayDlg = true;
        this.isEntryValid = true;
        this.isDataChanged = false;

        this.getAmendments();
        this.getBankAmountTypes();
        this.getBankAmountSubTypes();
        this.getBondInvoices();
        this.getCommunities();

        this.setCostToCompleteSaveReady();
        this.setDatesToUtc();
    }

    ngAfterContentInit(): void {
        this.blcNumber = this.bondData.number;
        this.proxyBlcData = this.bondData;
        this.sortAndFilterBankSuretyList();
    }

    anyFilesSelected(upload: any): boolean {
        for (const { } of upload?.files) {
            return false;
        }
        return true;
    }

    availableCommitmentChange(event: any): void {
        this.availableCommitment = event;
    }

    checkAmountTypeFinancialGuarantee(): void {
        if (this.isAmountTypeFinancialGuarantee) {
            this.availableCommitment = this.bankData.financialGuaranteeAvailableCommitment;

            //  Card 381991 Only apply these BLC rate "swaps" if status is active,expiring,pending !
            if (this.bondData.status == BondStatusEnum.Active || this.bondData.status == BondStatusEnum.Expiring ||
                this.bondData.status == BondStatusEnum.Pending) {
                this.bondData.rate = this.bankData.financialGuaranteeRate;
            }
        } else {
            this.availableCommitment = this.bankData.availableCommitment;

            //  Card 381991 Only apply these BLC rate "swaps" if status is active,expiring,pending !
            if (this.bondData.status == BondStatusEnum.Active || this.bondData.status == BondStatusEnum.Expiring ||
                this.bondData.status == BondStatusEnum.Pending) {
                this.bondData.rate = this.bankData.rate;
            }
        }
    }

    checkAutorenew(autoCheck): void {
        this.isAutoRenew = autoCheck;
    }

    clearCommunitySuggestions(): void {
        this.filteredCommunities = [];
    }

    clearSelectedCommunityValue(): void {
        this.communityAutocomplete.value = null;
        this.communityAutocomplete.inputEL.nativeElement.value = null;

        this.selectedCommunity = null;
    }

    clearValue(): void {
        this.selectedCommunity = null;
        this.bondData.communityNumber = null;
    }

    closeBLCModal(): void {
        this.editTabDisplayDlg = false;
        this.dialogClose.emit(false);
    }

    closeBLCModalWithUpdates(): void {
        this.editTabDisplayDlg = false;
        if (this.isSearchEditClicked) {
            this.onSearchAndEdit.emit();
        } else {
            this.onAdvancedSearch.emit();
        }
        this.dialogClose.emit(false);
    }

    changeFunction(item: any): void {
        this.bondData = item;
    }

    currentAmountChange(event: any): void {
        this.bondData.currentAmount = event;
    }

    getAmendments(): void {
        this.amendmentService.getAmendments(this.bondData.id).subscribe((res: Amendment[]) => {
            res.forEach((result) => {
                this.commonFunctions.trimTimeFromDatePropertiesInObject(result);
            });
            this.amendmentList = res;

            if (this.amendmentList.length === 0) {
                this.showNoResultsMessage = true;
            }
        }, () => {
            this.showNoResultsMessage = true;
            this.sendMessage('error', 'Error getting Amendments',
                'An unexpected error has occurred while getting Amendments for ' + this.initializeSubTableName() + '.');
        });
    }

    getAmountSubTypes(selectedValue: number = 0, isOnLoad: boolean = false): void {
        if (!isOnLoad) {
            this.bondData.amountSubTypeId = undefined;
        }

        if (!this.allAmountSubTypeList || this.allAmountSubTypeList.length == 0) {
            this.amountSubTypeService.getAmountSubTypes().subscribe((amountSubTypes: AmountSubType[]) => {
                this.allAmountSubTypeList = amountSubTypes;
                this.allAmountSubTypeList.sort((a, b) => a.name.localeCompare(b.name));
            }, () => {
                this.messageService.add({ severity: 'error', summary: 'Unexpected Error Occurred', detail: 'An error occurred getting Amount Sub Types.' });
            });
        }

        if (selectedValue > 0) {
            this.displayAmountSubTypeList = this.allAmountSubTypeList.filter(sub => sub.amountTypeId.toString() == selectedValue.toString());
            this.isAmountTypeSelected = true;
        } else {
            this.displayAmountSubTypeList = [];
            this.isAmountTypeSelected = false;
        }
    }

    getAmountSubTypeName(amountSubTypeId: number): string {
        if (this.allAmountSubTypeList === null) {
            this.amountSubTypeService.getAmountSubTypes().subscribe((amountSubTypes: AmountSubType[]) => {
                this.allAmountSubTypeList = amountSubTypes;
                this.allAmountSubTypeList.sort((a, b) => a.name.localeCompare(b.name));
            });
        }

        if (this.allAmountSubTypeList?.length > 0) {
            const result = this.allAmountSubTypeList.find(element => element.id === amountSubTypeId);
            return result?.name;
        }

        return null;
    }

    getAmountTypeName(id: number): string {
        if (this.amountTypeList?.length > 0) {
            const result = this.amountTypeList.find(element => element.id === id);
            return result?.name;
        }

        return null;
    }

    getBankAmountSubTypes(): void {
        if (this.bondData.amountTypeId == this.amountType.FinancialGuarantee || this.bondData.amountTypeId == this.amountType.Performance) {
            if (!this.allAmountSubTypeList || this.allAmountSubTypeList.length == 0) {
                this.amountSubTypeService.getAmountSubTypes().subscribe((amountSubTypes: AmountSubType[]) => {
                    this.allAmountSubTypeList = amountSubTypes;
                    this.allAmountSubTypeList.sort((a, b) => a.name.localeCompare(b.name));

                    this.displayAmountSubTypeList = this.allAmountSubTypeList.filter(sub => sub.amountTypeId == this.bondData.amountTypeId);
                    this.isAmountTypeSelected = true;
                });
            } else {
                this.displayAmountSubTypeList = this.allAmountSubTypeList.filter(sub => sub.amountTypeId == this.bondData.amountTypeId);
                this.isAmountTypeSelected = true;
            }
        } else {
            this.displayAmountSubTypeList = [];
            this.bondData.amountSubTypeId = undefined;
            this.isAmountTypeSelected = false;
        }        
    }

    getBankAmountTypes(): void {
        this.bankAmountTypeService.getBankAmountTypeByBank(this.bondData.bankId).subscribe((bankAmountTypes: BankAmountType[]) => {
            this.bankAmountTypeList = bankAmountTypes;
            this.reconcileBankAmountTypes();
        }, () => {
            this.showNoResultsMessage = true;
            this.sendMessage('error', 'Error getting Bank Amount Types',
                'An unexpected error has occurred while getting Bank Amount Types for ' + this.initializeSubTableName() + '.');
        });
    }

    getBankIsSurety(id: number): boolean {
        if (this.bankOrSuretyList?.length > 0) {
            const result = this.bankOrSuretyList.find(element => element.id === id);
            return result?.isSurety;
        }
        return false;
    }

    getBankName(id: number): string {
        if (this.bankOrSuretyList?.length > 0) {
            const result = this.bankOrSuretyList.find(element => element.id === id);
            return result?.name;
        }

        return null;
    }

    getBondInvoices(): void {
        this.bondService.getBondInvoices(this.bondData.id).subscribe((invoices: InvoiceBanksBonds[]) => {
            invoices.forEach((result) => {
                this.commonFunctions.trimTimeFromDatePropertiesInObject(result);
            });
            this.bondInvoices = invoices;
        });
    }

    getBondType(): string {
        if (this.bondData.isLetterOfCredit) {
            this.bondData.bondType = this.blcType.LetterOfCredit;
        } else {
            this.bondData.bondType = this.blcType.Bond;
        }
        if (this.bondData.bondType === 0) {
            this.bondType = 'Bond';
        } else {
            this.bondType = 'Letter of Credit';
        }
        return this.bondType;
    }

    getCommunities(): void {
        this.isCompanySelected = false;
        this.communityService.getCommunities(this.bondData.marketNumber).subscribe((communityData: Community[]) => {
            if (communityData?.length > 0) {
                this.communityList = communityData;
                this.commonFunctions.sortAndFormatCommunities(this.communityList);
                this.filteredCommunities = this.communityList;
            } else {
                this.communityList = [];
                this.filteredCommunities = [];
            }
            this.isCompanySelected = this.communityList?.length > 0 || this.filteredCommunities?.length > 0 ? true : false;
        }, () => {
            this.showNoResultsMessage = true;
            this.sendMessage('error', 'Error getting Communities', 'An unexpected error has occurred while getting Communities for ' + this.initializeSubTableName() + '.');
        }, () => { this.setSelectedCommunityValue(); });
    }

    getDocumentDownloadUrl(id: string): void {
        this.documentService.getDocumentDownloadUrl(id)
            .subscribe((download: any) => {
                const filename = download?.downloadUrl.split('/').pop().split('?')[0];

                try {
                    fileSaver.saveAs(download?.downloadUrl, filename);
                } catch {
                    this.sendMessage('error', 'Get Document Download URL Error', `Failed to get a download URL for id:${id} from Azure.`);
                }

            }, () => {
                this.sendMessage('error', 'Get Document Download URL Error', `Failed to get a download URL for id:${id} from Azure.`);
            });
    }

    getSizeInMegaBytes(file: File): number {
        return file ? file.size / 1000000 : 0;
    }

    initializeDialogHeader(): string {
        return this.bondData.isLetterOfCredit ? `Edit Letter of Credit: #${this.bondData.number}` : `Edit Bond: #${this.bondData.number}`;
    }

    initializeInvoiceHeader(): string {
        const bankOrSuretyName = this.getBankName(this.bondData.bankId);
        return this.bondData.isLetterOfCredit ? `Letter of Credit: #${this.bondData.number} from Bank: ${bankOrSuretyName} with ${this.bondInvoices?.length} matches` : `Bond: #${this.bondData.number} from Surety: ${bankOrSuretyName} with ${this.bondInvoices?.length} matches`;
    }

    initializeSubTableName(): string {
        return this.bondData.isLetterOfCredit ? `Letter of Credit (${this.bondData.number})` : `Bond (${this.bondData.number})`;
    }

    isBankOrSuretyNotAvailable(): boolean {
        if (this.bondData.status == BondStatusEnum.Canceled || this.bondData.status == BondStatusEnum.Expired) {
            return true;
        } else if (this.isStatusCanceled == true) {
            return true;
        } else {
            return false;
        }
    }

    isBond(): boolean {
        return this.bondData.isLetterOfCredit ? false : true;
    }

    isContentNotAvailable(controlName: string): boolean {
        if (controlName === 'BankOrSurety') {
            if (this.bondData.status == BondStatusEnum.Canceled || this.bondData.status == BondStatusEnum.Expired) {
                return true;
            } else {
                return false;
            }
        }

        if (controlName === 'BankOrLCType') {
            if (this.bondData.status == BondStatusEnum.Canceled || this.bondData.status == BondStatusEnum.Expired) {
                return true;
            } else {
                return false;
            }
        }

        if (controlName === 'CostToComplete') {
            if (this.isStatusPending()) {
                return true;
            } else {
                return false;
            }
        }

        if (controlName === 'InitialAmount') {
            if (this.isStatusPending() === false) {
                return true;
            } else {
                return false;
            }
        }
    }

    isDuplicateFile(fileSelection: DocumentParam): boolean {
        for (const doc of this.documents) {
            if (fileSelection.blobName.trim().toLowerCase() === doc.documentName.trim().toLowerCase()) {
                return true;
            }
        }
        return false;
    }

    isExceptionsUser(): boolean {
        return this.authService.isBlcExceptionsUser();
    }

    isFullAccessUser(): boolean {
        return this.authService.isBlcFullAccessUser();
    }

    isReadOnlyUser(): boolean {
        return this.authService.isBlcReadOnlyUser();
    }

    isInitialAmountNotAvailable(): boolean {
        if (this.bondData.status != BondStatusEnum.Pending) {
            return true;
        } else {
            return false;
        }
    }

    isStatusPending(): boolean {
        if (this.bondData.status == BondStatusEnum.Pending) {
            return true;
        } else {
            return false;
        }
    }

    modalEditAmendment(data: number): void {
        this.id = data;
        this.amendmentService.getAmendment(this.id).subscribe((amendment: Amendment) => {
            this.amendmentData = amendment;
            this.amendmentData.bondNewDateExpired = this.amendmentData.bondNewDateExpired ?
                this.amendmentData.bondNewDateExpired = this.blcDatePipe.transform(this.amendmentData.bondNewDateExpired) :
                this.amendmentData.bondNewDateExpired = null;
            this.amendmentData.dateMailed = this.amendmentData.dateMailed ?
                this.amendmentData.dateMailed = this.blcDatePipe.transform(this.amendmentData.dateMailed) :
                this.amendmentData.dateMailed = null;
            this.amendmentData.dateEffective = this.amendmentData.dateEffective ?
                this.amendmentData.dateEffective = this.blcDatePipe.transform(this.amendmentData.dateEffective) :
                this.amendmentData.dateEffective = null;
        }, () => {
            this.messageService.add({ severity: 'error', summary: 'Unexpected Error Occurred', detail: 'An error occurred getting Amendment.' });
        }, () => { this.displayAmendmentEditDialog = true; });
    }

    modalEditInvoice(data: number): void {
        this.id = data;
        this.invoiceService.getInvoice(this.id).subscribe((invoice: Invoice) => {
            this.invoiceData = JSON.parse(JSON.stringify(invoice));
            this.invoiceData.dateAp != null ?
                this.invoiceData.dateAp = this.blcDatePipe.transform(this.invoiceData.dateAp) :
                this.invoiceData.dateAp = null;
            this.invoiceData.datePayPeriodStart != null ?
                this.invoiceData.datePayPeriodStart = this.blcDatePipe.transform(this.invoiceData.datePayPeriodStart) :
                this.invoiceData.datePayPeriodStart = null;
            this.invoiceData.datePayPeriodEnd != null ?
                this.invoiceData.datePayPeriodEnd = this.blcDatePipe.transform(this.invoiceData.datePayPeriodEnd) :
                this.invoiceData.datePayPeriodEnd = null;

            this.invoiceData.bondNumber = this.bondInvoices[0].bondNumber;
        }, () => {
            this.messageService.add({ severity: 'error', summary: 'Unexpected Error Occurred', detail: 'An error occurred getting Invoice.' });
        }, () => { this.displayInvoiceDialog = true; });
    }

    modalOpenCreateAmendment(): void {
        // Display the modal
        this.displayAmendmentDialog = true;
        this.isAmendmentAddClicked = true;
    }

    modalOpenCreateClick(): void {
        // Display the modal
        this.displayAddDialog = true;
        this.isAddClicked = true;
    }

    onAmendmentDialogClose(): void {
        this.displayAmendmentDialog = false;
        this.isAmendmentAddClicked = false;
    }

    onAmountTypeChange(amountType: AmountTypeEnum): void {
        this.isAmountTypeFinancialGuarantee = false;
        this.bankOrSuretyId = this.bondData.bankId;

        if (amountType == AmountTypeEnum.FinancialGuarantee || amountType == AmountTypeEnum.Performance) {
            if (amountType == AmountTypeEnum.FinancialGuarantee) {
                this.isAmountTypeFinancialGuarantee = true;
            }

            this.bankService.getAvailableCommitment(this.bankOrSuretyId, this.isAmountTypeFinancialGuarantee).subscribe((bankInformation: BankWithFinancialGuarantee) => {
                this.bankData = bankInformation;
            }, () => { }, () => { this.checkAmountTypeFinancialGuarantee(); });

            this.getAmountSubTypes(amountType);
        } else {
            this.displayAmountSubTypeList = [];
            this.isAmountTypeSelected = false;
            this.isAmountTypeFinancialGuarantee = false;
            this.bondData.amountSubTypeId = undefined;
            this.bankOrSuretyId = this.bondData.bankId;

            this.bankService.getAvailableCommitment(this.bankOrSuretyId, this.isAmountTypeFinancialGuarantee).subscribe((bankInformation: BankWithFinancialGuarantee) => {
                this.bankData = bankInformation;
                this.availableCommitment = this.bankData.availableCommitment;

                //  Card 381991 Only apply these BLC rate "swaps" if status is active,expiring,pending !
                if (this.bondData.status == BondStatusEnum.Active || this.bondData.status == BondStatusEnum.Expiring ||
                    this.bondData.status == BondStatusEnum.Pending) {
                    this.bondData.rate = (this.isBankInformationChanged) ? this.bankData.rate : this.bondData.rate;
                }
            });
        }

        //  after all the app model massaging that went on above
        //  refresh the amount type selection from what was selected to begin with
        //  the bound app model's amount type id assignment is getting clobbered!
        this.bondData.amountTypeId = amountType;
    }

    onBankChange(value: any): void {
        //  change block card 383841
        //  because of the added compareWith of the bank and surety selection event
        //  the bound BLC data from input is not surviving so using a proxy to preserve
        //  then assign back.  That also happens on the after init event!
        this.bondData = this.proxyBlcData;
        const self = this;
        const selectedBankId = value.id;
        this.bondData.bank = value;
        this.bondData.bankId = selectedBankId;
        if (self.isAmountTypeFinancialGuarantee) {
            self.rate = value.financialGuaranteeRate;
        } else {
            self.rate = value.rate;
        }
        if (value.singleBondLimit != null) {
            self.singleBondLimit = value.singleBondLimit.toFixed(2);
        } else {
            self.singleBondLimit = '';
        }
        //  change block card 383841
        //  Card 369993 trumps card 381991, for the bank surety drop down when it change then the rate should override what is recorded in SQL!
        //  Card 381991 Only apply these BLC rate "swaps" if status is active,expiring,pending !
        this.bondData.rate = this.rate;
        this.bankService.getAvailableCommitment(selectedBankId, this.isAmountTypeFinancialGuarantee)
            .subscribe((bankInformation: BankWithFinancialGuarantee) => { this.bankData = bankInformation; }, () => { }, () => { this.checkAmountTypeFinancialGuarantee(); });

        this.bankOrSuretyId = selectedBankId;
        //  get this bank's bank amount types
        //  filter the amount types based on the bank selection !
        this.bankAmountTypeService.getBankAmountTypeByBank(this.bankOrSuretyId)
            .subscribe((bankAmountTypes: BankAmountType[]) => { this.bankAmountTypeList = bankAmountTypes; }, () => { }, () => { this.reconcileBankAmountTypes(); });
        this.proxyBlcData = this.bondData;
    }

    onBankInformationIsChanged(): void {
        this.isBankInformationChanged = true;
    }

    onBankSuretyCompare(bank1: Bank, bond2: Bond): boolean {
        this.proxyBlcData = bond2;
        return bank1 && bond2 ? bank1.id === bond2.bankId : bank1 === bond2?.bank;
    }

    onClearContent(fileUpload: any): void {
        fileUpload?.clear();
    }

    onCommentsChange(value): void {
        this.isCTCDataChanged = true;

        if (value !== null && value !== '' && value !== undefined) {
            this.bondData.comments = value;
        } else {
            this.bondData.comments = '';
        }
    }

    onCompanyChange(value: string): void {
        this.isCompanySelected = false;
        this.bondData.communityNumber = null;
        //  reset community selection first prior to community ac to rediscover
        //  its bound data
        this.clearSelectedCommunityValue();
        this.communityService.getCommunities(value).subscribe((communities: Community[]) => {
            if (communities?.length > 0) {
                this.communityList = communities;
                this.commonFunctions.sortAndFormatCommunities(this.communityList);
                this.filteredCommunities = this.communityList;
            } else {
                this.communityList = [];
                this.filteredCommunities = [];
            }
            this.isCompanySelected = this.communityList?.length > 0 || this.filteredCommunities?.length > 0 ? true : false;
        }, () => { }, () => { this.setSelectedCommunityValue(); });
    }

    onCommunitySelect(event: Community): void {
        this.selectedCommunity = event ? event : null;
        this.bondData.communityNumber = this.selectedCommunity?.communityNumber;
    }

    onCopyNumberClick(): void {
        try {
            navigator.clipboard.writeText(this.bondData.number.toString());
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Bond/LC Number copied to clipboard.' });
        } catch (ex) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Unable to copy Bond/LC Number to clipboard.' });
        }
    }

    onCostToCompleteChange(value: string): void {
        this.isCTCDataChanged = true;

        if (value !== null && value !== '' && value !== undefined) {
            const intValue = parseInt(value, 10);
            if (intValue >= 0 && intValue <= 100) {
                this.bondData.costToComplete = intValue;
                this.isEntryValid = true;
            } else {
                this.isEntryValid = false;
            }
        } else {
            this.bondData.costToComplete = null;
        }

        this.setCostToCompleteSaveReady();
    }

    onDialogClose(): void {
        this.displayAddDialog = false;
        this.isAddClicked = false;
    }

    onDocumentDescriptionKeyup(event: any): void {
        this.documentDescriptionToApply = event.target.value;
    }

    onFieldChange(): void {
        if (this.bondData.number && this.bondData.isLetterOfCredit && this.bondData.marketNumber && this.bondData.bankId &&
            this.bondData.amountTypeId && this.bondData.amount && this.bondData.beneficiary) {
            this.isSaveReady = true;
        } else {
            this.isSaveReady = false;
        }
    }

    onFileSelect(fileUpload: any): void {
        let resetUpload = false;
        let fileCount = 0;
        let fileSizeTotal = 0;

        fileUpload?.files.forEach((selected: File) => {
            fileSizeTotal = fileSizeTotal + selected.size;
            if (fileSizeTotal > this.maxUploadSize) {
                resetUpload = true;
                this.sendMessage('info', 'Size too big information', `All files included thus far totals: ${fileSizeTotal} and is too big.  Max file size is:  ${this.maxUploadSize}`);
            }
        });

        // tslint:disable-next-line: forin
        for (const file in fileUpload?.files) {
            fileCount++;
            if (fileCount > this.fileLimit) {
                resetUpload = true;
                this.sendMessage('info', 'Too many files per upload', `Too many files selected in this upload.  File limit is: ${this.fileLimit}.`);
            }
        }

        if (resetUpload) {
            fileUpload?.clear();
        }
    }

    onFilterCommunity(event: any): void {
        let filtered: any[] = [];
        let query = event?.query;

        for (let i = 0; i < this.communityList?.length; i++) {
            let community = this.communityList[i];
            let selCommunityFullName = `${community?.communityNumber} - ${community?.communityName}`;
            if (selCommunityFullName?.toLowerCase()?.includes(query?.toLowerCase())) {
                filtered.push(community);
            }
        }

        this.filteredCommunities = filtered?.length > 0 ? filtered : this.communityList;
    }

    onInputDateChange(evt: any, cal: any): void {
        this.isDataChanged = true;

        if (cal.el.nativeElement.id === 'issueDate') {
            if (evt.target?.value == '' || evt.target?.value == null || evt.target?.value == undefined) {
                this.bondData.dateIssued = null;
                return;
            }
        } else if (cal.el.nativeElement.id === 'expireDate') {
            if (evt.target?.value == '' || evt.target?.value == null || evt.target?.value == undefined) {
                this.bondData.dateExpired = null;
                this.bondData.currentDateExpired = null;
                return;
            }
        } else if (cal.el.nativeElement.id === 'cancelDate') {
            if (evt.target?.value == '' || evt.target?.value == null || evt.target?.value == undefined) {
                this.bondData.dateCanceled = null;
                return;
            }
        } else if (cal.el.nativeElement.id === 'EstimatedReleaseDate') {
            if (evt.target?.value == '' || evt.target?.value == null || evt.target?.value == undefined) {
                this.bondData.estimatedReleaseDate = null;
                this.isCTCDataChanged = true;
                this.setCostToCompleteSaveReady();
                return;
            }
        } else if (cal.el.nativeElement.id === 'mailDate') {
            if (evt.target?.value == '' || evt.target?.value == null || evt.target?.value == undefined) {
                this.bondData.dateMailed = null;
                return;
            }
        }
    }

    onInputDateSelect(evt: any, cal: any): void {
        this.isDataChanged = true;

        if (cal.el.nativeElement.id === 'issueDate') {
            this.bondData.dateIssued = this.blcDatePipe.transform(evt);
        } else if (cal.el.nativeElement.id === 'expireDate') {
            this.bondData.dateExpired = this.blcDatePipe.transform(evt);
            this.bondData.currentDateExpired = this.blcDatePipe.transform(evt);
        } else if (cal.el.nativeElement.id === 'cancelDate') {
            this.bondData.dateCanceled = this.blcDatePipe.transform(evt);
        } else if (cal.el.nativeElement.id === 'EstimatedReleaseDate') {
            this.bondData.estimatedReleaseDate = this.blcDatePipe.transform(evt);
            this.isCTCDataChanged = true;
            this.setCostToCompleteSaveReady();
        } else if (cal.el.nativeElement.id === 'mailDate') {
            this.bondData.dateMailed = this.blcDatePipe.transform(evt);
        }
    }

    onInputInitialAmount(event: any): void {
        if (event.value > this.maxAmount) {
            this.messageService.add({
                severity: 'error', summary: 'Error',
                detail: 'Initial Amount is out of range. Please set amount to less than ' + this.currencyPipe.transform(this.maxAmount, 'USD', 'symbol', '1.2-2') + '.'
            });
            this.bondData.amount = 0;
            return;
        }
        this.bondData.amount = event?.value;
        this.onFieldChange();
    }

    onModalAmendmentDialogClose(): void {
        this.displayAmendmentEditDialog = false;
    }

    onModalInvoiceDialogClose(): void {
        this.displayInvoiceDialog = false;
    }

    onNumberChange(value: number): void {
        if (this.blcNumber == value) {
            this.isNumberModified = false;
        } else {
            this.isNumberModified = true;
        }
    }

    onRateChange(event): void {
        const value = event.srcElement.value;
        if (!this.commonFunctions.isFloat(value) || Number.parseFloat(value) > this.rateMaxAmount) {
            this.messageService.add({
                severity: 'error', summary: 'Error',
                detail: 'Rate (bps) is out of range. Please set amount to less than ' + this.currencyPipe.transform(this.rateMaxAmount, 'USD', 'symbol', '1.2-2') + '.'
            });
            this.bondData.rate = 0;
            this.rate = 0;
            return;
        }
        this.bondData.rate = value;
        this.rate = value;
        this.onFieldChange();
    }

    onRemoveAmendmentClick(amendmentId: number): void {
        if (confirm(`Are you sure you want to remove amendment: ${amendmentId}?`)) {
            this.amendmentService.removeAmendment(amendmentId).pipe(
                switchMap(() => {
                    this.sendMessage('success', 'amendment Removal Success', `Successfully removed amendment.`);
                    this.getAmendments();
                    return this.bondService.updateBond(this.bondData);
                }),
                switchMap((bondUpdated: Bond) => {
                    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'The Bond/LC has been sucessfully updated' });
                    return this.bondService.getBondByNumber(this.bondData.number.toString());
                }),
                switchMap((fetchedData: Bond) => {
                    this.bondData = fetchedData;
                    this.bondData.bondType = this.commonFunctions.getBondType(this.bondData.isLetterOfCredit);
                    this.bondData = this.commonFunctions.getBondStatus(this.bondData).modifiedBond;
                    this.bondData.currentDateExpired = this.bondData.currentDateExpired ? this.blcDatePipe.transform(this.bondData.currentDateExpired) : null;
                    this.bondData.dateExpired = this.bondData.currentDateExpired;

                    if (this.bondData.amountTypeId === AmountTypeEnum.FinancialGuarantee) {
                        this.isAmountTypeFinancialGuarantee = true;
                    } else {
                        this.isAmountTypeFinancialGuarantee = false;
                    }

                    return this.bankService.getAvailableCommitment(this.bondData.bankId, this.isAmountTypeFinancialGuarantee);
                })
            ).subscribe((bankInformation: BankWithFinancialGuarantee) => {
                this.bankData = bankInformation;
                if (this.isAmountTypeFinancialGuarantee) {
                    this.availableCommitment = this.bankData.financialGuaranteeAvailableCommitment;
                }
                else {
                    this.availableCommitment = this.bankData.availableCommitment;
                }
                this.availableCommitmentChange(this.availableCommitment);
                this.changeFunction(this.bondData);
            }, (err) => {
                this.sendMessage('error', 'Unexpected Error Occurred', `An error occurred in removing amendment: ${amendmentId} - ${err}`);
            });
        }
    }

    onRemoveDocument(id: string, documentName: string): void {
        if (confirm(`Are you sure you want to remove ` + documentName + `?`)) {
            this.documentService.removeDocument(id).pipe(
                switchMap(() => {
                    return this.documentService.getDocumentsByBlcId(this.bondData.id);
                })
            ).subscribe((docs: Document[]) => {
                this.documents = docs;
            },
                () => { this.sendMessage('error', 'Unexpected Error Occurred', `An error occurred in removing ` + documentName); },
                () => {
                    this.sendMessage('success', 'Document Removal Success', `Document ${documentName} was successfully removed.`);
                    this.editTabDisplayDlg = true;
                });
        } else {
            return;
        }
    }

    onRemoveInvoiceClick(invoiceId: number): void {
        try {
            if (confirm(`Are you sure you want to remove invoice: ${invoiceId}?`)) {
                this.invoiceService.removeInvoice(invoiceId).subscribe(() => { this.sendMessage('success', 'Invoice Removal Successful', ``); }
                    , () => { this.sendMessage('error', 'Unexpected Error Occurred', `An error occurred in removing Invoice: ${invoiceId}`); }
                    , () => { this.getBondInvoices(); });
            } else {
                return;
            }
        } catch (error) {

        }
    }

    onSave(): void {
        //  clean up company/community before sending down stream!
        if (this.bondData.marketNumber === `-1` || this.bondData.marketNumber === undefined) {
            this.bondData.marketNumber = null;
        }
        if (this.bondData.communityNumber === `-1` || this.bondData.communityNumber === undefined || this.bondData.communityNumber?.toLowerCase() === ` -- select community --`) {
            this.bondData.communityNumber = null;
        }
        this.setDatesToUtc();
        if (this.validateFields() && this.validateBank()) {
            this.bondData.modifiedBy = this.bondModifiedBy;
            if (this.isNumberModified) {
                this.bondService.getBondByNumber(this.bondData.number.toString()).pipe(
                    switchMap((getBondData: Bond) => {
                        if (getBondData?.id > 0) {
                            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'A bond or letter of credit with this number already exists.  Please choose a different Bond/LC number.' });
                            return null;
                        } else {
                            return this.bondService.updateBond(this.bondData);
                        }
                    })
                ).subscribe(() => {
                    if (this.isCTCDataChanged) {
                        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'The cost to complete has been successfully updated' });
                    }
                    else {
                        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'The ' + this.bondType.toLowerCase() + ' has been successfully updated' });
                    }
                }, () => {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'An error occured while updating ' + this.bondType.toLowerCase() });
                }, async () => {

                    this.closeBLCModalWithUpdates();
                });
            }

            else {
                this.bondData.modifiedBy = this.user.loginname;
                this.bondService.updateBond(this.bondData).pipe(finalize(() => { this.closeBLCModalWithUpdates(); })).subscribe(() => {
                    if (this.isCTCDataChanged) {
                        this.sendMessage('success', 'Success', 'The cost to complete has been successfully updated');
                    }
                    else {
                        this.sendMessage('success', 'Success', 'The ' + this.bondType.toLowerCase() + ' has been successfully updated');
                    }
                }, () => {
                    this.sendMessage('error', 'Error', 'An error occured while updating ' + this.bondType.toLowerCase());
                });
            }
        }
    }

    onTypeChange(value: string): void {
        if (value === '0') {
            this.bondData.isLetterOfCredit = false;
        } else {
            this.bondData.isLetterOfCredit = true;
        }

        this.bondData.bankId = undefined;
        this.bankData = undefined;
        this.rate = null;
        this.singleBondLimit = undefined;
        this.availableCommitment = undefined;
        this.amountTypeListFiltered = [];
        this.displayAmountSubTypeList = [];
        this.bondData.amountTypeId = undefined;
        this.bondData.amountSubTypeId = undefined;

        this.bankOrSuretyList = this.fullBanksList.filter(bank => bank.isSurety == !this.bondData.isLetterOfCredit);
        this.sortAndFilterBankSuretyList();
    }

    reconcileBankAmountTypes(): void {
        this.amountTypeListFiltered = [];

        for (const _bat of this.bankAmountTypeList) {
            const filtered = this.amountTypeList.find(({ id }) => id === _bat.amountTypeId);
            if (filtered !== null) {
                this.amountTypeListFiltered.push(filtered);
            }
        }

        this.amountTypeListFiltered.sort((a, b) => a.name.localeCompare(b.name));
    }

    remove(): void {
        this.bondType = this.getBondType();
        if (confirm(`Are you sure you want to delete the ` + this.bondType.toLowerCase() + '?')) {
            this.bondService.removeBond(this.bondData.id).pipe(finalize(() => { this.closeBLCModalWithUpdates(); })).subscribe((bondRemoved: boolean) => {
                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'The ' + this.bondType.toLowerCase() + ' has been successfully removed' });
            }, () => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'An error occured removing ' + this.bondType.toLowerCase() });
            });
        }
    }

    removeFile(file: File, uploader: any, event: Event): void {
        const index = uploader?.files.indexOf(file);
        uploader?.remove(event, index);
    }

    sendChildMessage(messageArray: string[]): void {
        this.messageService.add({ severity: messageArray[0], summary: messageArray[1], detail: messageArray[2] });
    }

    sendMessage(severity: string, summary: string, detail: string): void {
        this.sendMessageEmit.emit([severity, summary, detail]);
    }

    setActiveIndex(): void {
        if (this.isCostToCompleteReport) {
            this.activeIndex = this.isBond() ? 2 : 0;
        }
        else if (this.isAmendmentReport) {
            this.activeIndex = this.isBond() ? 3 : 2;
        }
        else if (this.isInvoiceReport) {
            this.activeIndex = this.isBond() ? 4 : 3;
        }
        else {
            this.activeIndex = 0;
        }
    }

    setSelectedCommunityValue(): void {
        //  if community number is known then set the default selected
        //  community value!
        const comm = this.communityList?.find(element => element.communityNumber === this.bondData.communityNumber);
        if (comm) {
            const displayCommunityName = `${comm?.communityNumber} - ${comm?.communityName}`;
            this.selectedCommunity = comm;

            if (this.communityAutocomplete) {
                this.communityAutocomplete.value = displayCommunityName;
                this.communityAutocomplete.inputEL.nativeElement.value = displayCommunityName;
            }
        }
    }

    setCostToCompleteSaveReady(): void {
        if (this.bondData.costToComplete !== null && this.bondData.costToComplete !== undefined) {
            if (this.bondData.costToComplete >= 0 && this.bondData.costToComplete <= 100) {
                this.isEntryValid = true;
            } else {
                this.isEntryValid = false;
            }
        } else {
            this.bondData.costToComplete = null;
            this.isEntryValid = true;
        }

        if (this.isEntryValid) {
            this.ctcSaveReady = true;
            this.isDataChanged = true;
        } else {
            this.ctcSaveReady = false;
            this.isDataChanged = false;
        }
    }

    setDatesToUtc(): void {
        if (this.bondData.dateCanceled) {
            const utcd = this.blcDatePipe.transform(this.bondData.dateCanceled);
            this.bondData.dateCanceled = utcd;
        }

        if (this.bondData.dateExpired) {
            const utcd = this.blcDatePipe.transform(this.bondData.dateExpired);
            this.bondData.dateExpired = utcd;
        }

        if (this.bondData.currentDateExpired) {
            const utcd = this.blcDatePipe.transform(this.bondData.currentDateExpired);
            this.bondData.currentDateExpired = utcd;
            this.bondData.dateExpired = utcd;
        }

        if (this.bondData.dateIssued) {
            const utcd = this.blcDatePipe.transform(this.bondData.dateIssued);
            this.bondData.dateIssued = utcd;
        }

        if (this.bondData.estimatedReleaseDate) {
            const utcd = this.blcDatePipe.transform(this.bondData.estimatedReleaseDate);
            this.bondData.estimatedReleaseDate = utcd;
        }

        if (this.bondData.dateMailed) {
            const utcd = this.blcDatePipe.transform(this.bondData.dateMailed);
            this.bondData.dateMailed = utcd;
        }
    }

    showSelectedValue(selected: Community): string {
        return selected ? `${selected.communityNumber} - ${selected.communityName}` : null;
    }

    sortAndFilterBankSuretyList(): void {
        // First, sort by name
        this.bankOrSuretyList?.sort((a, b) => a.name.localeCompare(b.name));

        // Then, put all inactive at the bottom of the list
        this.bankOrSuretyList?.sort((a, b) => {
            return Number(b.isActive) - Number(a.isActive);
        });

        // Now, filter out unncessary records
        const filteredBanks: Bank[] = [];
        if (this.bondData.isLetterOfCredit) {
            this.bankOrSuretyList?.forEach(function (rec) {
                if (!rec.isSurety) {
                    filteredBanks.push(rec);
                }
            });
        } else {
            this.bankOrSuretyList?.forEach(function (rec) {
                if (rec.isSurety) {
                    filteredBanks.push(rec);
                }
            });
        }

        this.bankOrSuretyList = filteredBanks;
    }

    async uploadFileSelections(fileUpload: any): Promise<void> {
        try {
            const documentsForUpload: DocumentParam[] = [];
            for (const file of fileUpload?.files) {
                await this.commonFunctions.getByteArray(file)
                    .then((value: ArrayBuffer) => {
                        const newDocument = new DocumentParam();
                        const byteArr = new Uint8Array(value);
                        const base64 = this.commonFunctions.convertToBase64(byteArr);
                        newDocument.base64Content = base64;
                        newDocument.blobName = file.name;
                        newDocument.documentId = this.commonFunctions.generateUuid();
                        newDocument.fileDescription = this.documentDescriptionToApply;
                        newDocument.fileType = 'application/pdf';
                        newDocument.uploadBy = this.user.loginname;
                        newDocument.blcId = this.bondData.id;
                        newDocument.contentType = 'application/pdf';
                        if (this.isDuplicateFile(newDocument) === false) {
                            documentsForUpload.push(newDocument);
                        } else {
                            this.sendMessage('info', `Duplicate document information.`, `Document: ${newDocument.blobName} already exists.`);
                        }
                    });
            }
            for (const doc of documentsForUpload) {
                this.documentService.uploadDocumentToBlobContainer(doc).pipe(
                    switchMap((uploaded: boolean) => {
                        this.documentDescriptionToApply = null;
                        if (uploaded) {
                            this.documentDescriptionToApply = null;
                            this.sendMessage('success', `Upload And Create Document Success.`, `Document: ${doc.blobName} uploaded successfully.`);
                        } else {
                            this.sendMessage('error', 'Unexpected Error Occurred', `An error occurred uploading a document in Azure blob`);
                        }
                        return this.documentService.getDocumentsByBlcId(this.bondData.id);
                    })
                ).subscribe((documentsReturned: Document[]) => {
                    this.documents = documentsReturned;
                });
            }
            this.editTabDisplayDlg = true;
            this.onClearContent(fileUpload);
        } catch (error) {
            this.sendMessage('error', 'Unexpected Error Occurred', `An error occurred uploading a document in Azure blob`);
        }
    }

    validateBank(): boolean {
        if (this.bondData.bankId != 0) {
            this.bondType = this.getBondType();
            if (this.isStatusPending()) {
                if (this.bondData.currentAmount > Number(this.availableCommitment)) {
                    this.sendMessage('warn', 'Warn', 'The currently selected bank or surety does not have enough available commitment to service this ' + this.bondType.toLowerCase() + '.');
                    this.success = true;
                } else if (this.bondData.currentAmount > Number(this.singleBondLimit)) {
                    this.sendMessage('warn', 'Warn', 'The currently selected bank or surety has a single bond limit less than the current amount for this ' + this.bondType.toLowerCase() + '.');
                    this.success = true;
                } else {
                    this.success = true;
                }
            } else {
                this.success = true;
            }
        }
        return this.success;
    }

    validateFields(): boolean {
        const estimatedReleaseDate = new Date(this.bondData.estimatedReleaseDate);
        const expiredDate = new Date(this.bondData.dateExpired);
        const issuedDate = new Date(this.bondData.dateIssued);

        if (this.bondData.isLetterOfCredit === undefined) {
            this.sendMessage('error', 'Error', 'Please select the Type.');
            return false;
        }

        if (this.bondData.marketNumber === undefined) {
            this.sendMessage('error', 'Error', 'Please select the company.');
            return false;
        }

        if (this.bondData.bankId === undefined) {
            this.sendMessage('error', 'Error', 'Please select the bank.');
            return false;
        }

        if (this.bondData.amountTypeId === undefined) {
            this.sendMessage('error', 'Error', 'Please select the bond type.');
            return false;
        }

        if ((Number(this.bondData.amountTypeId) === AmountTypeEnum.FinancialGuarantee ||
            Number(this.bondData.amountTypeId) === AmountTypeEnum.Performance) && this.bondData.amountSubTypeId == undefined) {
            this.sendMessage('error', 'Error', 'Please select the bond subtype.');
            return false;
        }

        if (this.bondData.amount <= 0) {
            this.sendMessage('error', 'Error', 'Please enter a valid initial amount.');
            return false;
        }
        if (!this.bondData.amount) {
            this.sendMessage('error', 'Error', 'Please enter the initial amount.');
            return false;
        }

        if (!this.bondData.rate) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please enter the rate.' });
            return false;
        }

        if (!this.bondData.beneficiary) {
            this.sendMessage('error', 'Error', 'Please enter the beneficiary.');
            return false;
        }

        if (this.bondData.dateIssued && !this.bondData.number) {
            this.sendMessage('error', 'Error', 'Please enter the bond number.');
            return false;
        }

        if (this.bondData.dateIssued && this.bondData.number && (!this.bondData.dateExpired || !this.bondData.currentDateExpired)) {
            this.sendMessage('error', 'Error', 'Please enter the expire date.');
            return false;
        }

        if (this.bondData.dateExpired && this.bondData.dateIssued && expiredDate <= issuedDate) {
            this.sendMessage('error', 'Error', 'The Date Issued must preceed the Expiration Date');
            return false;
        }

        if (this.bondData.estimatedReleaseDate && this.bondData.dateIssued && estimatedReleaseDate <= issuedDate) {
            this.sendMessage('error', 'Error', 'The Date Issued must preceed the Estimated Release Date');
            return false;
        }

        return true;
    }
}
